<template>
  <div class="title-box">{{ title }}</div>
</template>
<script>
export default {
  name: "HomeSectionTitle",
  props: {
    title: {
      type: String,
      default: "title text...",
    },
  },
};
</script>
<style lang="scss" scoped>
.title-box {
  color: black;
  position: relative;
  font-weight: 900;
  font-size: 32px;
  line-height: 36px;
  padding-bottom: 15px;
  margin-top: 20px;
  margin-bottom: 30px;
}
.title-box:first-child {
  margin-top: 0;
}
.title-box::after {
  position: absolute;
  left: 0;
  bottom: 0;
  width: 10%;
  height: 4px;
  content: "";
  background: black;
}
</style>